import React, { useEffect } from "react";
import { navigate } from "gatsby";

const ErrorPage = () => {
  useEffect(() => {
    navigate("/");
  }, []);

  return null;
};

export default ErrorPage;
